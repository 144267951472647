#root {
    .ant-btn {
        border-radius: 5px;
    }
}

.ant-modal {
    .ant-modal-header {
        border-bottom: none;
        border-radius: 5px 5px 0 0;
    }

    .ant-modal-content {
        border-radius: 5px;
    }

    .ant-modal-footer {
        border-top: none;
        border-radius: 0 0 5px 5px;
    }
}

.ant-rate {
    color: #febd55;

    .ant-rate-star:not(:last-child) {
        margin-right: 1px;
    }

    .ant-rate-star-zero {
        opacity: 0.5;
        .ant-rate-star-first,
        .ant-rate-star-second {
            color: #d3d0fb;
        }
    }
}
