@import "Colors";
@import "Transitions";
@import "Easing";
@import "Icon";
@import "AppBar";
@import "Table";
@import "Print";

@import url("https://fonts.googleapis.com/css?family=Lato:300&display=swap");

@font-face {
    font-family: "moblab";
    src: url("../fonts/moblab.eot?#iefix") format("embedded-opentype"),
        url("../fonts/moblab.woff") format("woff"),
        url("../fonts/moblab.ttf") format("truetype"),
        url("../fonts/moblab.svg#moblab") format("svg");
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 16px;
}

body {
    font-family: "Lato", "Roboto", "Open-Sans", sans-serif;
    font-weight: 400;
    background-color: $white_1;
    overflow-y: hidden;
}
#root {
    height: 100%;
    * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        color-adjust: exact !important; /*Firefox*/
    }
}

.App {
    text-align: center;
    height: 100vh;

    .View {
        position: relative;
        background: white;
        height: calc(100% - 4.75rem);
        width: 100%;

        &.Students {
            padding: 3.5rem;
            padding-top: 1.5rem;
            text-align: left;
            overflow-y: auto;
        }

        .sidebar {
            background: rgba(240, 242, 245, 1);
            left: 0;
            position: absolute;
            overflow-y: hidden;

            &:hover {
                overflow-y: auto;
            }

            .navGroup {
                padding: 2rem;
                padding-top: 1.2rem;
                padding-bottom: 0;
                text-align: left;

                h3 {
                    color: rgba(#102037, 0.4);
                    font-size: 0.875rem;
                }

                .Ripple {
                    width: calc(100% + 4rem);
                    border-radius: 0px;
                    margin-left: -2rem;
                    margin-right: -2rem;
                    padding: 2px 0 2px 2rem;
                    font-weight: bold;

                    &.selected {
                        box-shadow: none;
                        background: rgba(#102037, 0.2);
                        color: #202324;
                    }
                }
            }
        }
        .main {
            position: relative;
            background: #f7f9fa;
            overflow-y: auto;
        }
    }
    .loading_app {
        position: relative;
        height: calc(100% - 4.75rem);
        width: 100%;
    }
    .Logo {
        height: 2rem;

        &.printLogo {
            display: none;
        }
    }
    .inline-link {
        color: #00a0bc;
        cursor: pointer;

        &:hover {
            color: #2f5791;
            text-decoration: underline;
        }
    }
}

.PasswordRequirements {
    display: inline-block;
    width: auto;
    text-align: left;
    padding-left: 1rem;

    h2 {
        font-size: 1rem;
        margin-left: -1rem;
        margin-bottom: 0.5rem;
        color: #47525d;
    }

    li {
        font-size: 0.875rem;
        font-weight: 400;
        color: #a1a8b3;
    }

    .invalid {
        color: #ee2a49;
    }
}
.ui.segment.Undo {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    z-index: 1000;
    border: none;
    box-shadow: none;
    background: rgba(black, 0.8);
    color: white;
    font-size: 0.875rem;
    animation: slide-up 0.4s $easeJeff;

    span {
        margin-right: 1.5rem;

        &.undo_action {
            color: #00a0bc;
            cursor: pointer;
        }
    }
    .icon {
        display: inline-block;
        margin-top: -0.2rem;
        vertical-align: middle;
        cursor: pointer;
    }
}

//Semantic UI Overrides
.ui {
    /******************** UI BUTTON STYLING ********************/
    @mixin button_override($color) {
        color: white;
        background: $color;

        &.inverted,
        &.focus {
            background: none;
            color: $color;
            box-shadow: 0 0 0 2px $color inset !important;

            &:hover {
                background: $color;
                color: white;
                filter: brightness(100%);
            }
        }

        &:hover {
            background: rgba($color, 0.8);
            color: white;
        }
    }
    &.button {
        font-size: 1.25em;
        padding: 0.7em 1em;

        &:not(:disabled) {
            cursor: pointer;

            &.blue,
            &.positive {
                @include button_override(#00a0bc);
            }
            &.black {
                @include button_override(#47525d);
            }
            &.hide {
                cursor: default;
            }
        }
        &:disabled,
        &.disabled,
        &.loading,
        &.disabled.loading {
            box-shadow: none !important;
            opacity: 1 !important;
            background: #e8eaed;
        }
    }
    /******************** UI FORM STYLING ********************/
    &.form {
        .field {
            position: relative;
            text-align: left;
            padding-top: 2rem;
            margin-bottom: 0.5rem;
            transition: all 0.2s;

            label,
            input {
                font-family: "Roboto";
                font-size: 1rem;
                font-weight: 400;
            }
            label {
                color: #a1a8b3;
                margin: 0 0 -2rem 0;
                overflow: hidden;
                transition: all 0.2s;
                user-select: none;
            }
            select {
                margin-top: 0.5rem;
                height: 2rem;
                padding: 0;
                padding-left: 0.5rem;
            }
            i.icon {
                float: right;
                margin-top: -3.2rem;
                opacity: 0.5;
                padding-top: 0.5rem;
                height: 2rem;
                width: 2rem;
                cursor: pointer;
            }

            .ui.input {
                input {
                    line-height: 1.2rem;
                    border: none;
                    border-radius: 0 !important;
                    margin-bottom: 1rem;
                    padding-left: 0;
                    width: 100%;
                    text-align: left;
                    display: block;
                    transition: all 0.5s;
                }

                i.icon {
                    height: 2.7rem;
                }
            }
            .ui.checkbox {
                padding: 0.25rem 0;

                label {
                    font-size: 1rem;
                    line-height: 2rem;
                    font-weight: 400;
                    color: #47525d;
                    padding-left: 3rem;

                    $checkbox_ease: cubic-bezier(0.4, 0, 0.2, 1);

                    &:before {
                        width: 2rem;
                        height: 2rem;
                        border: 2px solid #a1a8b3;
                        border-radius: 0.5rem;
                        transition: background 120ms 100ms $checkbox_ease,
                            border 200ms $checkbox_ease,
                            box-shadow 260ms $checkbox_ease;
                    }
                    &:after {
                        font-family: "moblab";
                        content: ">";
                        text-shadow: 0px 3px 1px rgba(#102037, 0.2),
                            0px 2px 2px rgba(#102037, 0.14),
                            0px 1px 5px rgba(#102037, 0.12);
                        color: #ffffff;
                        height: 100%;
                        width: 2rem;
                        font-size: 1.4em;
                        text-align: center;
                        vertical-align: middle;
                        position: absolute;
                        transform: scale(0);
                        opacity: 0;
                        transition: transform 180ms $checkbox_ease,
                            text-shadow 100ms $checkbox_ease,
                            opacity 100ms $checkbox_ease;
                    }
                }
                &.checked label {
                    &:before {
                        border-color: #00a0bc;
                        background-color: #00a0bc;
                        border-radius: 25%;
                        box-shadow: inset 0px 0px 0px 20px #00a0bc;
                    }
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &:disabled label {
                    &:before {
                        border-color: #e5e5e5;
                        background: #e5e5e5;
                        box-shadow: inset 0px 0px 0px 0px #e5e5e5;
                        cursor: default;
                    }

                    &:after {
                        opacity: 0.8 !important;
                        color: #ffffff !important;
                        text-shadow: 0px 3px 1px rgba(#102037, 0.1),
                            0px 2px 2px rgba(#102037, 0.04),
                            0px 1px 5px rgba(#102037, 0.02) !important;
                    }
                }

                &:hover {
                    &:not(.checked):not(:disabled) label:before {
                        border-color: #00a0bc;
                    }
                    &:checked:not(:disabled) label:after,
                    &:indeterminate:not(:disabled) label:after {
                        transform: scale(1.2);
                        text-shadow: 0px 2px 4px rgba(#102037, 0.2),
                            0px 4px 5px rgba(#102037, 0.14),
                            0px 1px 10px rgba(#102037, 0.12);
                    }
                    &:checked:not(:disabled) label:after,
                    &:indeterminate:not(:disabled) label:after {
                        transform: scale(1);
                        text-shadow: 0px 3px 1px rgba(#102037, 0.2),
                            0px 2px 2px rgba(#102037, 0.14),
                            0px 1px 5px rgba(#102037, 0.12);
                    }
                }
            }

            &:not(.error) {
                .ui.input input {
                    background: none;
                    border-bottom: 1px solid rgba(#a1a8b3, 0.2);
                }
            }
            &.focused,
            &:not(.empty),
            &.open {
                padding-top: 0;

                label {
                    // font-size: 0.875rem;
                    transform: scale(0.875) translateX(-7.5%);
                    margin: 0;
                }
            }
        }
    }
    &.segment {
        border: none;
    }
    /******************** OTHER UI STYLING ********************/
    &.dropdown {
        &.selection {
            border: none;
            border-radius: 6px !important;
        }
        .dropdown.icon {
            &:before {
                font-family: "moblab";
                user-select: none;
                content: "9";
            }
            &.clear:before {
                content: "\0065";
            }
        }
        .text {
            word-wrap: break-word;
            max-width: calc(100% - 2rem);
        }
    }
    &.popup {
        animation: fadein 0.2s;

        &.tip {
            padding: 0.375rem 1rem;

            .content {
                font-size: 0.875rem;
            }
        }
    }
    &.dimmer {
        animation-duration: 0s;
        transition: none;
        animation: fadein 0.1s;
    }
}

.blurring.dimmed.dimmable > :not(.dimmer) {
    filter: blur(5px);
    transition: filter 0s;
}
