@import './Easing';

.NoteDrawer {
    text-align: left;
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;

    h1, h2 {
        padding: 1.5rem 1.5rem 0;
    }
    h1 {
        display: inline-block;
        color: #202324;
        margin: 0;
        font-size: 1.5rem;        
        vertical-align: top;
        user-select: none;
        transition: all 0.2s $easeJeff;

        span {
            margin-left: -1rem;
            opacity: 0;
            font-size: 1.1rem;
            transition: all 0.2s $easeJeff
        }
    }
    h2 {
        color: #A1A8B3;
        margin: 0;
        font-size: 1.125rem;
        font-weight: 400;
        margin-bottom: 3rem;
        padding-top: 1rem;
    }
    .showAll {
        position: absolute;
        top: 1.9rem;
        right: 1rem;
        font-size: 0.875rem;
        color: #A1A8B3;
        cursor: pointer;

        &:hover {
            color: #4386CB;
        }
    }
    
    &.edit {
        h1 {
            cursor: pointer;
            margin-bottom: 1rem;
            font-size: 1.2rem;

            span {
                margin-left: 0;
                font-size: 0.88rem;
                opacity: 1;
            }
        }
        h2 {
            display: none;
        }

        .editorBox {
            margin-top: 0;
        }
        .showAll {
            display: none;
        }
    }

    .editorBox {
        position: absolute;
        top: 4rem;
        margin-left: 20rem;
        width: 100%;
        min-height: 4rem;
        transition: all 0.2s $easeJeff;

        &.visible {
            margin-left: 0;

            .NoteEditor {
                padding-top: 0;

                .stats {
                    div:first-of-type {
                        display: none;
                    }
                }
            }
        
            .RichTextEditor__editor___1QqIU {
                margin-left: 0;
                height: calc(100vh - 35rem);
            }
        }
    }

    .scrollArea {
        height: calc(100vh - 23rem);
        overflow-y: auto;

        // &:hover {
        //     box-shadow: -6px 0px 6px rgba(#102037, 0.16) inset;
        // }
    }
    .Ripple.addNote {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        border-radius: 4px;
    }
}
.NoteDirectory {
    .noteRow {
        position: relative;
        padding: 0.5rem 1.5rem;
        border-bottom: 1px solid rgba(#102037, 0.16);
        color: #47525D;
        overflow: hidden;
        text-align: left;
        cursor: pointer;
        transition: all 0.4s $easeJeff;

        &.deleted {
            height: 0;
            padding: 0 1.5rem;
            opacity: 0;
            border: none;
        }
        &:hover, &.selected {
            background: rgba(#4386CB, 0.08);
        }
        &:hover {
            .icon {
                &.go {
                    color: #102037;
                    right: 0.75rem;
                    opacity: 1;
                }
                &.trash {
                    display: block;
                    cursor: pointer;
                    right: 2rem;
                   
                    &:hover {
                        color: #102037;
                    }
                }
            }
        }

        h3 {
            font-size: 0.875rem;
            margin: 0;
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        span {
            font-size: 0.75rem;
            margin-right: 1rem;
            color: #A1A8B3;
        }
        .icon {
            position: absolute;
            font-size: 1.25rem;
            top: 1.125rem;
            color: #A1A8B3;

            &.go {
                right: 1.5rem;
                opacity: 0;
                transition: all 0.2s $easeJeff;
            }
            &.trash {
                padding: 1.06rem 0.5rem;
                top: 0rem;
                right: -5rem;
                transition: color 0.2s $easeJeff;
            }
        }
    }
    .no_notes {
        padding: 0 1.5rem;
    }
}
.NoteEditor {
    padding: 1.5rem;

    .EditorToolbar__root___3_Aqz {
        margin: 0;
        padding: 0.5rem;

        button {
            background: none;
            border: none;

            &:hover, &:active {
                background: rgba(#102037, 0.16) !important;
            }

            &.IconButton__isActive___2Ey8p {
                background: rgba(#102037, 0.24) !important;
            }
        }
    }

    .ui.form .disabled.field {
        opacity: 1;

        label {
            opacity: 1;
        }
    }

    .stats {
        padding-top: 1rem;
        font-size: 0.875rem;
        font-style: italic;
        color: #A1A8B3;

        div {
            display: inline-block;
            margin-right: 2rem;
        }
    }

    &.readOnly {
        .editor {
            border: none;
        }

        .title {
            margin-top: -1px;

            label, div {
                font-family: 'Roboto';
                font-size: 1rem;
                font-weight: 400;
            }
            label {
                color: #A1A8B3;
            }
            div {
                overflow: auto;
                line-height: 1.2rem;
                border-bottom: 1px solid rgba(#A1A8B3, 0.2);
                margin: 0.7rem 0 1rem;
                padding-bottom: 0.7rem;
            }
        }
    }
}

.Page.Notes {
    height: 100%;
    overflow: hidden;
    text-align: left;

    $menuWidth: 20rem;

    .menu {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: $menuWidth;
        height: 100%;
        border-right: 1px solid rgba(#102037, 0.16);
        
        .actions {
            width: 100%;
            padding: 1rem;

            .addNote {
                border-radius: 4px;
            }
        }
        .scrollArea {
            height: calc(100% - 3.75rem);
            overflow: auto;
            padding-bottom: 4rem;
        }
    }
    .pit {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - #{$menuWidth});
        height: 100%;
        padding: 1.5rem 2rem;

        .NoteEditor {
            padding-top: 1rem;

            .RichTextEditor__editor___1QqIU {
                max-height: calc(100vh - 26rem);
                overflow-y: auto;

                .public-DraftEditor-content {
                    min-height: calc(100vh - 50rem);
                }
            }
        }
    }
}