.App .View.Admin {
    $sidebar_width: 15rem;
    padding: 0;

    .sidebar {
        width: $sidebar_width;
        height: 100%;

        .navGroup:first-of-type {
            padding-bottom: 0;
        }
    }
    .main {
        width: calc( 100% - #{$sidebar_width} );
        height: 100%;
        left: $sidebar_width;
        background: #F7F9FA;
        text-align: left;

        .InteractiveTable {
            padding: 2rem;

            .ui.table {
                thead tr:first-child th:first-child {
                    text-align: center;
                }
            }
        }
        
        .Statistics {
            width: 100%;
            height: 100%;

            .filters {
                width: 100%;
                padding: 1rem;
                background: #E8EAED;

                .ui.dropdown.selection { 
                    background: none;
                    vertical-align: top;
                    width: 32%;
                    margin-right: 2%;
                    // border-bottom: 1px solid rgba(#102037, 0.16);
                    // border-bottom-left-radius: 0px !important;
                    // border-bottom-right-radius: 0px !important;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.active .menu {
                        border-color: rgba(#102037, 0.16);
                    }

                    a.ui.label {
                        position: relative;
                        max-width: 45%;
                        overflow-x: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding-right: 1.5rem;

                        i.delete.icon {
                            position: absolute;
                            right: 0.5rem;
                        }
                    }
                }
            }

            .charts {

                .section {
                    padding: 2rem;

                    h2 {
                        font-size: 1.125rem;
                        color: #47525D;
                    }

                    .Card.Statistic {
                        display: inline-block;
                        width: calc(50% - 1rem);
                        min-width: 25rem;

                        &:nth-child(even) {
                            margin-right: 2rem;
                        }
                    }
                }
            }
        }
        .InteractiveTable .results {
            display: none!important;
        }
    }
}