@media print {
    @page {
        /* portrait */
        /* size: 21cm 29.7cm; A4 portrait; size: 210mm 297mm;
			margin: 30mm 45mm 30mm 45mm; */
        size: A4 portrait;
        /* margin: 1cm 1cm 1cm 1cm; */
        margin: 0 !important;
    }

    html,
    body {
        height: auto !important;
        min-height: 100%;
    }
}
