$default_color: #935B8E;
$selected_color: #00A0BC;
$dh: 4rem;
$height: 10rem;

.suggested_tier {
    .bg {
        position: absolute;
        left: 0;
        bottom: calc(-#{$height} * 0.1);
        width: 100%;
        height: $height;
        padding-right: 1rem;
        text-align: right;

        .bar {
            width: 3rem;
            margin-left: 4px;
            height: 200%;
            background: $default_color;
            opacity: 0.3;
            border-radius: 10rem;
            transform: rotate(15deg);
            display: inline-block;
            vertical-align: top;
            transition: margin 0.2s;

            &.h0 {
                margin-top: $dh;
            }
            &.h1 {
                margin-top: 0;
                margin-left: calc(#{$dh} * 0.32);
                margin-right: calc(-#{$dh} * 0.26);
                opacity: 0.6;
            }
        }
    }
    
    .plan {
        font-size: 2.875rem;
        line-height: 4.5rem;
        color: $default_color;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .score {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: bold;
        color: rgba(#47525D, 1);
    }

    &.selected {
        .bg .bar {
            background: $selected_color;
        }
        .plan {
            color: $selected_color;
        }
        // &.clickable:hover {
        //     .bar.h1 {
        //         margin-left: 4px;
        //         margin-right: 0;
        //         margin-top: $dh;
        //     }
        // }
    }
}
