@media print {
    @page {
        /* portrait */
        size: A4 portrait;
        /* 边距 上右下左 */
        margin: 1cm 1cm 1cm 1cm;
    }
    html {
        font-size: 14px !important;
    }
    //chrome only
    // @supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee))
    //     and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
    //     @page {
    //         // size: landscape;
    //         size: A4 landscape;
    //     }
    // }
    #root {
        height: auto;

        .App {
            height: auto;

            .View {
                height: auto;

                .ui.segment {
                    box-shadow: none;
                }
                &.Profile {
                    .nav {
                        background: unset;
                        > h1 {
                            color: #202324;
                        }
                    }

                    .notesBtn {
                        display: none;
                    }

                    .main {
                        left: 0;
                        width: 100%;
                    }

                    .Page {
                        padding-bottom: 0;

                        &.SchoolFit.is-non-vision-account {
                            page-break-before: always !important;
                            page-break-inside: avoid !important;
                        }

                        &.PlanReport {
                            .row {
                                &.unsuggested-tier,
                                &.inconsistent-tier {
                                    display: none !important;
                                }
                                .suggested-tier-card {
                                    display: none;
                                }
                                .assigned-tier-card {
                                    display: block !important;
                                }
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                // display: inline-block;
                                // text-align: right;
                                // page-break-before: avoid !important;
                                page-break-inside: always !important;
                                // page-break-after: always !important;
                                // border-top: 1px solid #a4a4a4;

                                // &:last-child {
                                //     padding: none;
                                // }

                                .TierCard {
                                    // display: inline-block;
                                    // position: relative;
                                    // width: auto;
                                    // height: auto;
                                    // left: 0;
                                    // top: 0.5rem;
                                    // text-align: center;

                                    .ui.segment {
                                        // width: 16rem;
                                        // padding-bottom: 6rem;

                                        h1 {
                                            min-height: 23rem;
                                            // box-shadow: none;
                                            // margin: 0;
                                            // padding: 3rem 2rem;
                                            // background: none;
                                            // color: black;
                                        }
                                    }
                                }

                                // .suggested_tier,
                                // .scores_overview,
                                .ui.segment.description {
                                    // page-break-before: avoid !important;
                                    page-break-inside: always !important;
                                    // page-break-after: always !important;
                                    // width: calc(100% - 18rem);
                                    // box-shadow: none;
                                    // border: none;
                                    // background: none;
                                }
                                // .ui.segment.description {
                                //     float: right;
                                // }
                            }
                        }

                        .traits_detail {
                            .column .column div div div:not(.slider-handles) {
                                border: 1px solid #a1a8b3;
                            }
                        }

                        .trait-bar {
                            color-adjust: exact;
                        }

                        .row .TierCard.disabled .ui.segment,
                        // .row .ui.segment.description div,
                        .traits_detail .ui.two.column.grid,
                        .field.disabled {
                            page-break-inside: always !important;
                        }

                        .chartContainer {
                            max-width: 100%;
                        }
                    }
                }
            }

            .left-nav-bar,
            .sidebar,
            .AppBar,
            .nav h2,
            .nav .ui.dropdown,
            .nav .print-all-button,
            .icon.help {
                display: none !important;
            }

            img.Logo.printLogo {
                display: block;
                position: absolute;
                left: 1rem;
                top: 1.75rem;
                color: black;
            }
        }
    }
}
