.searchipt input {
    color: blue !important;
    // font-style: italic;
}
.searchipt {
    color: blue !important;
    // font-style: italic;
}

.ant-table {
    th {
        /* background: gray!important; */
        text-align: center !important;
    }

    tbody {
        background: white;
    }
    tbody tr {
        line-height: 0.2;
    }
}

.menu span {
    width: 50px;
    text-align: left;
}
.menu i {
    margin-left: 60% !important;
}
.grayrow {
    background: rgb(230, 232, 233);
}
.grayrow td {
    background: rgb(230, 232, 233);
}

.editableSelect {
    height: 20px !important;
    width: 102% !important;
    margin-left: -1%;
}

.editableSelect .ant-select-arrow {
    margin-top: -3px;
    margin-right: 2%;
    display: none;
}
.editableSelect .ant-select-selector .ant-select-selection-item {
    font-size: 14px;
    width: 100% !important;
    margin-left: -6px;
    line-height: 25px !important;
    padding-top: 2px !important;
}

.editable > .ant-table-cell {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.editable-cell-value-wrap {
    height: 20px !important;
    line-height: 20px !important;
}
.uneditable-cell-value-wrap {
    height: 20px !important;
    line-height: 20px !important;
}

.ant-table-cell {
    .editableInput {
        height: 28px !important;
        width: 140% !important;
    }

    .ant-form-item {
        height: 20px !important;
        width: 100% !important;
        margin-top: 0px !important;
        overflow: hidden;
    }

    .ant-form-item-control {
        margin-top: -8px;
    }

    .ant-row.ant-form-item {
        .ant-input {
            padding-left: 5px;
            padding-top: 8px;
        }
    }

    .ant-picker-input > input {
        padding-top: 5px !important;
        padding-left: 0 !important;
    }

    .editableAutoComplete .ant-select-selector .ant-select-selection-search {
        margin-left: -7px;
        padding-top: 2px;
    }

    .editableDate {
        height: 28px !important;
        width: 110% !important;
        margin-left: -6px;
        margin-top: 0;
        .ant-picker-input .ant-picker-suffix {
            display: none;
        }
        .ant-picker-input .ant-picker-clear {
            display: none;
        }
    }
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
    background-color: #fff !important;
    &:hover {
        background-color: #fff !important;
    }
}

.modalDropdown {
    height: 30px !important;
    min-height: 20px !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    line-height: 2px !important;
}
.modalDropdown i {
    line-height: 2px !important;
}

.syncRow {
    background-color: #e5f5fe;
    > .ant-table-cell-fix-left,
    > .ant-table-cell-fix-right {
        background-color: #fff;
        &:hover {
            background-color: #fff;
        }
    }
}

.ant-table-tbody > tr.ant-table-row.syncRow:hover > td {
    background: #e5f5fe;
}
.ant-table-tbody > tr.ant-table-row.syncRow .editable-cell-value-wrap {
    padding-left: 5px;
    border: 1px solid #e5f5fe !important;
}
.redRow {
    background-color: #fff0f5 !important;
}
.redRow:hover {
    background: #fff0f5 !important;
}
.redRow .editable-cell-value-wrap {
    padding-left: 5px !important;
    // background-color: red;
    border: 1px solid #fff0f5 !important;
}
.disabled {
    color: gray;
}
.disabled:hover {
    color: gray;
}

.update-submission-model {
    .ant-modal-body {
        padding-top: 12px;
    }
}

.nopadding > * {
    padding-right: 0px !important; //TODO
}
.textarea {
    margin-top: 10px !important;
}
.textarea > div {
    height: 100% !important;
}
