@import "./Easing";

@mixin majorInfo {
    z-index: 1000;
    margin-top: -2px;

    .details {
        padding: 1rem 0 1rem 2rem;

        h3,
        p {
            text-align: center;
        }

        .items {
            width: calc(100% - 2rem);
            margin: 1rem 1rem 1rem;

            .item {
                display: inline-block;
                margin-right: 1.5rem;
                margin-bottom: 1rem;
                color: #00a0bc;
            }
        }
        p.income {
            font-size: 1.125rem;
            font-weight: 900;
            color: #00a0bc;
        }
    }
}

.Widget.ui.segment {
    height: auto;
    text-align: left;
    vertical-align: top;
    box-shadow: 0 1px 4px rgba(#102037, 0.16);
    border: none;
    border-radius: 8px;
    overflow: hidden;

    h1 {
        font-size: 1.5rem;
        color: rgba(#202324, 1);
        margin: 0;
        margin-bottom: 0.5rem;
    }
    h2 {
        font-size: 1.125rem;
        font-weight: 400;
        color: #a1a8b3;
        margin: 0;
    }
    h1,
    h2,
    h3 {
        text-align: left;
    }
    .icon.help,
    .icon.more {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: #a1a8b3;
        cursor: pointer;
    }

    &.clickable {
        transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;

        &:hover {
            box-shadow: 0 3px 12px rgba(#102037, 0.16);
        }
    }

    &.FormSegment {
        .react-datepicker-wrapper {
            width: 100%;

            div {
                width: 100%;
            }
        }
        .ui.input:not(.disabled) input[disabled] {
            opacity: 1;
        }
    }

    &.traits_overview div {
        text-align: center;
    }

    &.language_overview .stats {
        width: calc(100% - 2rem);
        text-align: center;

        .stat {
            display: inline-block;
            max-width: 40%;
            padding: 1rem 2rem;
            border-radius: 8px;
            margin: 1rem 0 1rem;
            // background: rgba(#A1A8B3, 0.4);
            // color: white;

            // &:first-child {
            //     margin: 1rem 2rem 1rem 0;
            // }

            h3 {
                margin-bottom: 0.5rem;
            }
        }
    }

    &.exam_summary {
        overflow-x: auto;

        .chartContainer {
            min-height: 15rem;
            text-align: center;
            padding-top: 3rem;

            .legend {
                // position: absolute;
                float: left;
                text-align: left;
                margin-left: 2rem;
                width: 15rem;
                height: 60%;
                overflow: auto;

                .exam_label {
                    padding: 0.25rem 0;
                    cursor: default;

                    .dot {
                        display: inline-block;
                        width: 1rem;
                        height: 1rem;
                        border-radius: 8px;
                    }
                    span {
                        font-size: 1rem;
                        line-height: 1rem;
                        vertical-align: top;
                        margin-left: 0.5rem;
                    }
                }
            }

            .recharts-wrapper {
                .recharts-radial-bar-background {
                    path:first-of-type {
                        display: none;
                    }
                }
            }
        }
        .no_data {
            text-align: center;
        }
    }
    &.exam_info {
        min-height: 20rem;
        min-width: 100%;
        overflow: visible;

        .actions {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #a1a8b3;

            .icon,
            .viewBtn {
                display: inline-block;
                user-select: none;
                cursor: pointer;

                &:not(.editing):hover {
                    color: #4386cb;
                }
            }

            .icon {
                font-size: 1.5rem;

                &.editing {
                    opacity: 0;
                    cursor: default;
                }
            }
            .viewBtn {
                vertical-align: top;
                font-size: 0.75rem;
                padding: 0 1rem;
                margin-right: 1rem;
                line-height: 1.5rem;
            }
        }

        .addBar {
            position: relative;
            min-height: 2rem;
            width: 100%;
            text-align: center;

            .addExam {
                .icon {
                    margin-top: 0.45rem;
                }
            }
        }

        .recharts-responsive-container {
            margin-left: calc(5% - 2rem);
        }
        .recharts-legend-wrapper {
            margin-left: 3rem;
        }
        .ExamTable {
            margin: 1rem 4rem 1rem 2rem;
            width: calc(100% - 8rem);
        }

        &.invalid {
            .actions {
                opacity: 0.4;

                .viewBtn {
                    cursor: default;

                    &:hover {
                        color: #a1a8b3;
                    }
                }
            }
            .ui.table.ExamTable {
                td.invalid input:not(:focus) {
                    background: rgba(red, 0.2);
                }
            }
        }
    }
    &.major {
        .majorTable {
            margin-bottom: 0.5rem;
        }
        $graphWidth: 15rem;

        .left_section,
        .right_section {
            display: inline-block;
        }
        .left_section {
            width: calc(100% - #{$graphWidth});
        }
        .right_section {
            width: $graphWidth;
        }
        .actions {
            text-align: center;
            margin-bottom: 1rem;

            .Ripple .text {
                color: #a1a8b3;
            }
        }
    }

    &.school_info {
        padding-left: 2rem;

        h1 {
            font-size: 1.25rem;
            width: 50%;
            color: #202324;
        }
        h2 {
            font-size: 1rem;
            color: #47525d;
        }
        h3 {
            font-size: 1.125rem;
            color: #47525d;
        }
        .icon {
            display: inline-block;
            margin-right: 0.25rem;
            vertical-align: top;

            &.location {
                font-size: 1.25rem;
                color: #4386cb;
            }
            &.trophy {
                margin-top: 0.125rem;
                margin-left: 0.1rem;
                color: #ee8c2a;
            }
        }
        .RadialPercent {
            position: absolute;
            right: 2rem;
            top: 1rem;
            width: calc(50% - 4rem);
        }
        .compareBar {
            width: calc(100% + 3rem);
            height: 3.125rem;
            background: rgba(#e8eaed, 0.3);
            margin: 0 0 -1rem -2rem;
        }
    }

    &.major_info {
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: all 0.1s $easeJeff;

        h1 {
            font-size: 1.25rem;
            font-weight: 400;
        }
        h3 {
            font-size: 0.875rem;
        }
        .left_section,
        .right_section {
            vertical-align: top;
        }
        .overview {
            padding: 2rem 0 1rem 2rem;
            border-bottom: 1px solid rgba(#102037, 0.16);
        }
        .details {
            position: relative;
            transition: padding 0.2s $easeJeff;

            .left_section,
            .right_section {
                overflow: hidden;
                transition: height 0.2 $easeJeff;
            }
            .divider {
                display: inline-block;
                position: relative;
                width: 1px;
                margin-right: 3rem;
                margin-top: 0.7rem;
                height: 10rem;
                background: #e8eaed;
            }
            .right_section {
                width: 9rem;
            }
        }

        &:not(.open) {
            background: #f7f8fa;
            box-shadow: none;
            border-radius: 0;
            z-index: 0;

            .details {
                height: 0;
                padding: 0;
                padding-left: 2rem;

                .left_section,
                .right_section {
                    height: 0;
                }
            }
        }
        @media print {
            @include majorInfo;
            background: white !important;
            box-shadow: 0 1px 4px rgba(16, 32, 55, 0.16) !important;
            border-radius: 8px !important;
            z-index: 1000 !important;
            .details {
                height: auto !important;
                padding: 1rem 0 1rem 2rem !important;

                .left_section,
                .right_section {
                    height: auto !important;
                }
            }
        }
        &.open {
            @include majorInfo;
        }
    }
}
