$status_width: 25%;
$status_transition_dur: 0.2s;

.View.Profile .main .Page {
    position: relative;
    width: 100%;
    padding: 1rem 1.5rem 2rem 1.5rem;

    .ui.grid {
        margin: 0;

        .row {
            padding: 0.6rem 0 0.6rem 0;

            .column {
                padding: 0 0.4rem 0 0.4rem;

            }

            .Widget {
                height: 100%;
            }
        }
    }
    .recharts-wrapper {
        display: inline-block;
    }
    .no_data {
        width: 100%;
        padding: 2rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: #47525D;
    }

    &.Dashboard {

        .ui.segment.disabled {
            opacity: 1;
        }

        /********************* TIER ASSIGNMENT BAR *********************/
        .student_action {
            position: relative;
            text-align: left;
            cursor: pointer;
            padding: 0px;

            &.status_0,
            &.status_2 {
                background: white;
                color: #202324;

                .status {
                    background: rgba(#4386CB, 0.16);
                }
            }

            &.status_1 {
                background: rgba(#A29BD6, 1);
                color: White;
            }

            &.status_3 {
                background: #A1A8B3;
                color: White;
            }

            div {
                vertical-align: top;
                font-weight: bold;
            }

            .status {
                display: inline-block;
                width: $status_width;
                padding: 1.5rem 1rem 1.5rem 1rem;
                background: rgba(#102037, 0.16);
                overflow: hidden;
                white-space: nowrap;
                font-style: italic;
                transition: all $status_transition_dur;
            }

            .action {
                position: absolute;
                left: $status_width;
                transform: translateX(0);
                display: inline-block;
                padding: 1.5rem 0;
                margin-left: 1.5rem;
                transition: all $status_transition_dur;

                &.action_-1 {
                    margin-top: -0.5rem;
                }
                &.action_0 {
                    font-size: 1.25rem;
                    color: #4386CB;
                    margin-left: 2rem;
                }
                p {
                    font-size: 0.875rem;
                    color: #4386CB;
                    font-weight: 400;
                }
                .icon {
                    display: inline-block;
                    vertical-align: top;
                    margin: 0.25rem 0.25rem 0 0.25rem;
                    font-size: 0.8rem;
                }
            }

            .Ripple {
                position: absolute;
                right: 1rem;
                top: 1rem;
                background: rgba(#4386CB, 0.16);
                // color: white;
                font-size: 0.875rem;
            }

            &.disabled {
                cursor: default;
            }

            &:not(.disabled):hover {
                .status {
                    width: 100%;
                    // padding: 1.5rem 0 1.5rem 0;
                    color: rgba(white, 0);
                }
                .action {
                    left: 50%;
                    margin-left: 0;
                    transform: translateX(-50%);
                }
            }
        }

        /********************* GAME PROGRESS *********************/
        .game_progress {
            text-align: left;
            /* padding-left: 0.8rem; */
            font-size: 1.125rem;
            color: #47525D;

            text {
                font-size: 1.5rem;
                font-weight: bold;
                color: #47525D;
                animation: fadein 0.4s;
            }

            .six.wide.column .game_progress .recharts-wrapper {
                margin-top: 2rem;
            }

            .recharts-wrapper {
                margin-top: 0;
            }
        }
        /********************* TRAITS *********************/
        .ui.segment.traits_overview {
            text-align: left;

            h2 {
                margin-bottom: 2rem;
            }

            text {
                font-size: 0.8rem;
            }
        }

        .archive_date {
            margin-top: 2rem;
            font-size: 1rem;
            color: rgba(#47525D, 1);
        }

        .footer {
            text-align: left;
            padding: 1rem;

            .linkedDesc {
                font-size: 1.125rem;
                margin-bottom: 1rem;
            }
        }
    }

    &.StudentInfo {
        text-align: left;

        .FormSegment {
            h1 {
                font-size: 1.71rem;
                margin-bottom: 1.5rem;
            }

            .icon {
                font-size: 1.5rem;
                position: absolute;
                right: 1.5rem;
                top: 1.5rem;
                cursor: pointer;
            }

            .ui.form {
                .field.focused label{
                    color: #4386CB;
                }
                label {
                    color: #A1A8B3;
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                    opacity: 1;
                }
                .ui.question {
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                    margin-bottom: 1rem;
                }
                .ui.input input[type=text],
                .ui.input input[type=date] {
                    border: none;
                    padding-left: 0;
                    padding-top: 0;
                    padding-bottom: 0.5rem;
                    margin-bottom: 1rem;
                    font-size: 1.43rem;

                    &:not(:disabled) {
                        border-bottom: 1px solid rgba(#222426, 0.1);
                        border-radius: 0!important;

                        &:focus {
                            border-color: #4386CB;
                        }
                    }
                }
                .ui.selection.dropdown {
                    font-size: 1.2rem;
                    margin: 0.5rem 0;
                    border: 1px solid rgba(#222426, 0.1);

                    .icon.dropdown {
                        line-height: 1rem;
                        padding: 1.2rem;
                    }

                    &:focus, &.active .menu {
                        border-color: #4386CB;
                    }

                    &.disabled {
                        border: none;
                        padding: 1rem 0;
                        opacity: 1;

                        .icon.dropdown {
                            opacity: 0;
                        }
                    }
                }
                .disabled.field,
                .disabled.input {
                    opacity: 1;
                }
            }

            .actions {
                text-align: right;

                .Ripple.empty.flat {
                    margin-right: 2rem;
                }
                .ui.button {
                    font-size: 1rem;
                }
            }
        }
    }

    &.PlanReport {

        .row:first-child {
            margin-bottom: 1rem;
        }

        .ui.segment.description {
            height: calc(100% - 1.25rem);
            width: calc(100% - 14rem);
            display: block;
            margin: 0.5rem;
            text-align: left;
            padding: 1rem 2rem 1rem 1.5rem;

            h2 {
                margin: 0 0 0.2rem;
                font-size: 1rem;
                color: #47525D;
            }
            p {
                margin-bottom: 0.5rem;
            }
        }
    }

    &.Notes {
        padding: 0rem;

        h1 {
            text-align: left;
            padding: 2rem 0 0 2rem;
            margin-bottom: 0;
            color: #A1A8B3;
        }
    }

    &.SchoolFit {
        height: 100%;
        text-align: left;

        .Card.SchoolList {
            height: 100%;
            text-align: center;
            $filter_width: 18rem;

            h2 {
                width: calc(100% - 6rem);
            }

            .viewBtn {
                position: absolute;
                right: 4rem;
                top: 1.125rem;
                color: #A1A8B3;
                user-select: none;
                cursor: pointer;
            }

            .filters, .content {
                position: relative;
                display: inline-block;
                height: calc(100% - 5rem);
                margin-top: 2rem;
                vertical-align: top;
            }
            .ui.form.filters {
                width: $filter_width;
                // border-right: 1px solid #E8EAED;
                padding-right: 1rem;
                overflow-y: auto;

                &::-webkit-scrollbar { width: 0 !important }
                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;

                h2 {
                    width: 100%;
                    font-size: 1rem;
                    font-weight: bold;
                    color: #47525D;
                    margin-bottom: 1rem;
                }

                .field .ui.input input {
                    padding-left: 0.5rem;
                }

                .filter {
                    margin-bottom: 1rem;
                    text-align: left;

                    label {
                        font-family: 'Roboto';
                        font-size: 0.875rem;
                        font-weight: 400;
                        color: #A1A8B3;
                    }

                    .ui.dropdown {
                        width: 100%;
                        border-bottom: 1px solid #E8EAED;
                        border-radius: 0!important;

                        // &.active:hover {
                        //     box-shadow: none;
                        // }
                        .text {
                            margin-left: 0;
                            user-select: none;
                        }
                        // .menu {
                        //     border-top-width: 1px!important;
                        //     border-top-color: #E8EAED;
                        // }
                    }
                    i.icon {
                        font-size: 0.75rem;
                    }
                    .rc-slider {
                        margin: 0.5rem 1rem;
                        width: calc(100% - 2rem);

                        .rc-slider-rail {
                            background-color: #A1A8B3;
                        }
                        .rc-slider-track {
                            background-color: #00BFD6;
                            border: none;
                        }
                        .rc-slider-handle{
                            border-color: #A1A8B3;
                        }
                    }
                    .currentRange {
                        text-align: center;
                        font-weight: bold;
                        font-size: 1rem;
                        color: #47525D;
                    }
                }
            }
            .content {
                position: relative;
                width: calc(100% - #{$filter_width} - 1rem);
                margin-left: 1rem;

                .actions {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background: white;
                    text-align: right;
                    padding-bottom: 0.25rem;
                    padding-right: 1rem;
                    // border-bottom: 1px solid #E8EAED;
                    transition: box-shadow 0.2s cubic-bezier(0.4,0.0,0.2,1);;

                    &.raised {
                        box-shadow: 0 4px 6px -6px rgba(1, 22, 22, 0.16);
                    }

                    .Ripple {
                        width: 2rem;
                        height: 2rem;
                        font-size: 0.8rem;

                        .icon {
                            position: absolute;
                            top: 15%;
                        }
                        &.back .icon{
                            left: 28%;
                        }
                        &.next .icon{
                            left: 32%;
                        }
                    }
                    .range {
                        font-size: 0.8rem;
                        display: inline-block;
                        vertical-align: top;
                        margin-top: 0.5rem;
                        margin-right: 0.5rem;
                    }
                }

                .scrollArea {
                    height: calc(100% - 2rem);
                    overflow-y: auto;
                    padding: 1rem;
                    margin-top: 2.25rem;
                    // padding-left: 2rem;
                }
            }

            table.ui.table {
                
                td:first-child, th:first-child {
                    text-align: center;
                }
                
                .name {
                    font-size: 1.125rem;
                    color: #202324;
                }
                .loc {
                    font-size: 1rem;
                    color: #47525D;
                }
            }
        }
    }

    &.Exams {
        min-width: 50rem;
    }

    &.MajorFit {
        .majors {
            margin-top: 2rem;
        }
    }
}
