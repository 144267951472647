$icons: (
    beaker: '\0041',
    check-circle: '\0042',
    guide: '\0043',
    copy: '\0045',
    download: '\0046',
    eye-disabled: '\0047',
    eye: '\0048',
    gamepad-o: '\0049',
    gamepad: '\004a',
    help-circle-o: '\004b',
    help-circle: '\004c',
    info-circle-o: '\004d',
    info-circle: '\004e',
    instructions: '\004f',
    moblab: '\0050',
    modules: '\0051',
    modules-expanded: '\00c4',
    module-link: '\00c3',
    monkey-full: '\0052',
    running: '\0053',
    random: '\0054',
    results: '\0055',
    schedule-a: '\0056',
    schedule-b: '\0057',
    search: '\0058',
    star-o: '\0059',
    star: '\005a',
    survey: '\0061',
    trash: '\0062',
    trophy-o: '\0063',
    trophy: '\0064',
    x-thick: '\0065',
    x-thin: '\0066',
    envelope: '\0067',
    envelope-o: '\00C0',
    heart-o: '\0068',
    lock: '\0069',
    lock-o: '\00a8',
    network: '\006a',
    settings: '\006b',
    strategy: '\006c',
    theory: '\006d',
    refresh: '\006e',
    time: '\006f',
    calendar: '\0070',
    location: '\0071',
    connect: '\0072',
    connect-o: '\0073',
    idea: '\0074',
    history: '\0075',
    home: '\0076',
    chat: '\0077',
    account: '\0078',
    group: '\0079',
    playlist: '\0030',
    connecting: '\0031',
    plus-circle: '\0032',
    minus-circle: '\0033',
    play-circle: '\0034',
    template-o: '\0035',
    play: '\0036',
    education: '\0037',
    instructor: '\0038',
    arrow-down: '\0039',
    arrow-left: '\0021',
    arrow-right: '\0022',
    arrow-up: '\0023',
    phone: '\0024',
    edit: '\0025',
    beaker-o: '\0026',
    s-survey:'\0027',
    sort:'\0028',
    plus:'\0029',
    upload: '\002a',
    drag: '\002b',
    menu: '\002c',
    configure: '\002d',
    monitor: '\002e',
    arrow-full-down: '\002f',
    arrow-full-up: '\005b',
    decision: '\005d',
    prob: '\005e',
    auctions: '\005f',
    bargain: '\0060',
    decmaking: '\007b',
    finmac: '\007c',
    indust: '\007d',
    markets: '\007e',
    pubchoice: '\003f',
    move: '\005c',
    invite-pending:'\0052',
    invite-send: '\007a',
    chevron-left: '\003A',
    chevron-right: '\003B',
    gift: '\003C',
    check: '\003e',
    gradebook: '\0044',
    class: '\003D',
    join-class: '\0040',
    minus: '\00a4',
    export: '\00AB',
    preview: '\00A7',
    note: '\00B0',
    overview: '\00B1',
    breakdown: '\00B2',
    qr: '\00AA',
    notification-o: '\00A9',
    notification: '\00A2'
);

// .micon {
//     display: inline-block;
//     font: normal normal normal 14px/1 'moblab';
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale
// }

// For adding font icons to elements using CSS pseudo-elements
// http://jaydenseric.com/blog/fun-with-sass-and-font-icons
@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }
    // Either a :before or :after pseudo-element, or both, defaulting to :before
    &:#{$position} {
        @if $icon {
            // A particular icon has been specified
            content: "#{map-get($icons, $icon)}";
        }
        @if $styles {
            // Supportive icon styles required
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-family: 'moblab';
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }
        // Include any extra rules supplied for the pseudo-element
        @content;
    }
}

i.icon, .icon {
    font-family: 'moblab';
    // font-size: 2rem;
    user-select: none;
    line-height: 1em;
    
    &.eye {
        @include icon(before, eye);
    }
    &.eye_disabled {
        @include icon(before, eye-disabled);
    }
    &.delete{
        @include icon(before, x-thick);
    }
}