@import './Easing';

.ui.table {
    background: none;
    border: none;
    color: #102037;
    text-align: center;

    th:first-child,
    td:first-child {
        padding-left: 1em;
        text-align: left;
    }

    thead {
        th {
            background: none;
            color: #47525D;
            font-size: 1rem;
            font-weight: 400;
            transition: background-color 0.2s;
            user-select: none;
            border: none;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .icon.arrow {
                display: inline-block;
                margin-left: 0.5em;
                line-height:0.5em;
                vertical-align: middle;
                opacity: 0;
                width: 0;
                overflow: hidden;

                &::before {
                    content: "/";
                }
            }

            &.ascending .icon.arrow,
            &.descending .icon.arrow {
                opacity: 1;
                width: auto;
            }
            &.descending .icon.arrow::before {
                content: "[";
            }
            &:not(.disabled) {
                cursor: pointer;
                &:hover {
                    background: rgba(#4086CC,.06);
                }
            }
        }
        tr:first-child th:first-child,
        tr:last-child th:last-child {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
    } 
    tbody {
        font-size: 1.5rem;

        tr {
            background: rgba(#FAFBFC, 1);
            // transition: all 0.4s $easeJeff;

            &.deleted {
                // height: 0;
                // padding: 0 1.5rem;
                // opacity: 0;
                // border: none;
                display: none;
            }
            &:not(.disabled) {
                cursor: pointer;
                &:hover {
                    background: rgba(#00A0BC, 0.06);
                    color: #4386CB;
                }
            }
            &:nth-child(2n){
                background: rgba(#F7F8FA, 1);
            }

            td {
                border: none;
                box-shadow: 0 1px 0px rgba(#102037, 0.16);
                padding: 0.75rem;

                &:first-child{
                    font-size: 1.25rem;
                }

                .link {
                    color: #4386CB;
                    &:hover {
                        color: "#00A0BC";
                        cursor: pointer;
                    }
                }
                i.icon {
                    font-family: 'moblab';
                    font-size: 1.2em;
                    color: #A1A8B3;
                    
                    &.yes {
                        color: rgb(64,134,204);
                    }
                    &.no {
                        transform: rotate(45deg);
                    }
                }
                .react-datepicker-wrapper {
                    min-width: 10rem;
                }
                input, select {
                    width: 100%;
                    border-radius: 0;
                    height: 2.5rem;
                }
                input {
                    padding: 0.75rem;
                    text-align: center;
                    background: none;
                    border: none;

                    &:focus, &:hover {
                        background: rgba(white, 0.8);
                        outline: none;                            
                    }
                    &.invalid {
                        background: rgba(red, 0.2);
                    }
                }
                select {
                    border: none;
                    background: none;

                    &.empty {
                        color: rgba(#102037, 0.2);
                    }
                }

                &.editable {
                    padding: 0;

                    input {
                        padding: 0.75rem;
                        text-align: center;
                        background: none;
                        border: none;
    
                        &:focus, &:hover {
                            background: rgba(white, 0.8);
                            outline: none;                            
                        }
                    }
                    &.invalid {
                        select {
                            color: #A1A8B3;
                        }
                    }
                }
                &.trash {
                    background: white;
                    box-shadow: none;
                    padding-right: 1rem;
                    
                    .icon {
                        opacity: 0;
                    }
                }
                &:hover {
                    select {
                        background: white;
                    }
                }
            }

            &.disabled td {
                color: #102037;
            }
            &:hover {
                td.trash {
                    color: #102037;
                    cursor: pointer;

                    .icon {
                        opacity: 1;
                    }

                    &:hover {
                        color: #4386CB;
                    }
                }
            }
            // &:not(:first-of-type) {
            //     td.editable input {
            //         &:focus, &:hover {
            //             box-shadow: inset 0 1px 0px rgba(#102037, 0.16);
            //         }
            //     }
            // }
        }
    }

    &.editable {
        tbody tr:not(.disabled) {
            cursor: default;

            &:hover {
                color: inherit;
            }
        }
    }

    &.ExamTable {
        thead {
            th {
                font-size: 1rem;
            }
        }
        tbody {
            tr {
                cursor: default;
                
                td {
                    font-size: 1.125rem;
                    padding: 0;
                    
                    &:first-child {
                        width: 1rem;
                    }
                }
            }
        }
    }
    &.majorTable {
        margin-top: 0;
        margin-bottom: 2rem;
    }
}