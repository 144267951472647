.Component.AppBar {
    display: table;
    width: 100%;
    height: auto;
    background-image: linear-gradient(to right, #4386CB, #935B8E);
    text-align: left;
    color: white;
    user-select: none;

    &.admin {
        background: #47525D;
    }

    .nav {
        vertical-align: middle;
        width: 100%;
        height: 5rem;
        padding: 1.5rem 2.5rem 1.5rem 2.5rem;
        user-select: none;

        .Logo {
            position: absolute;
            left: 2rem;
            top: 1.5rem;
            user-select: none;
        }

        .appbar-link-container {
            width: 100%;
            display: inline-block;

            .adminToggle {
                display: inline-block;
                margin: 0.5rem auto 0.875rem 9rem;
                padding: 0rem 0.5rem;
                border-radius: 100px;
                background: #102037;
                color: #A1A8B3;
                cursor: pointer;
    
                &:hover {
                    opacity: 0.5;
                }
            }

            .ui.dropdown, .appbar-support-link {
                float: right;
            }
            .appbar-support-link {
                margin-right: 2rem;
                margin-top: 0.5rem;
                padding-bottom: 0.375rem;
                font-size: 1rem;
                font-weight: 300;
                color: white;
            }
            .ui.dropdown {
                position: relative;
                float: right;
    
                .Ripple {
                    width: 3rem;
                    height: 3rem;
                    margin-top: -0.4rem;
                    padding: 0;
                    color: white;
                    font-size: 2rem;
                    font-weight: 100;
                    line-height: 2rem;
                    user-select: none;
                    border-radius: 100%;
                    cursor: pointer;
    
                    .icon {
                        margin: 0.45rem 0rem 0 0.5rem;
                    }
                }
                i {
                    display: none;
                }
            }
        }
    }
}
