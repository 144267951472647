
.Ripple {
    position: relative;
    display: inline-block;
    font-size: 1.125rem;
    line-height: 2rem;
    color: #47525D;
    user-select: none;
    box-shadow: 0 1px 3px rgba(#102037,0.1);
    border-radius: 4em;
    vertical-align: middle;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    cursor: pointer;
    
    .icon,
    .text {
        display: inline-block;
        vertical-align: top;
    }
    .icon {
        margin-right: 0.4rem;
        margin-top: 0.4rem;
    }
    .fill {
        background: #4386CB;
        opacity: 0.06;
        width: 0;
        position: absolute;
        transition: all 0.2s;

        &.center {
            left: 50%;
            top: 50%;
            padding-top: 0%;
            border-radius: 100%;
            transform: translate(-50%, -50%);
        }
        &.left {
            left: 0px;
            top: 0px;
            height: 100%;
        }
    }

    &.empty {
        background: none;
        color: #A1A8B3;
    }
    &.white {
        .fill {
            background: white;
            color: white;
            opacity: 0.12;
        }
    }
    &.flat {
        box-shadow: none;

        &:not(.disabled):hover {
            box-shadow: none;
        }
    }
    
    
    &.disabled {
        opacity: 0.6;
        cursor: default;
    }
    &:not(.disabled):hover {
        box-shadow: 0 3px 6px rgba(#102037,0.1);
    }
    &:not(.selected):not(.disabled):hover .fill,
    &:not(.selected):not(.disabled):active .fill {
        &.center {
            width: 150%;
            padding-top: 150%;
            border-radius: 8px;
        }
        &.left {
            left: 0px;
            top: 0px;
            width: 100%;
        }
        
    }
}