.FilterSelect {
    display: inline-block;
    font-size: 1rem;
}
.FilterSelect .field {
    display: inline-block;
}
.FilterSelect .Ripple {
    box-shadow: none;
}
/* Active Filter Styling */
.FilterSelect .filter {
    background: white;
    border-radius: 3.125em;
    box-shadow: 0 1px 3px rgba(#000, 0.1);
    color: #4386CB;
    cursor: pointer;
    display: inline-block;
    margin-top: 0.35em;
    margin-bottom: 0.6em;
    user-select: none;
    vertical-align: top;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    /* Inactive Styling */
    margin-left: 0;
    padding: 0.2em 0 0.2em 0;
    transition: all 0.1s;
    width: 0px;
}
.FilterSelect .filter.active {
    position: relative;
    width: auto;
    max-width: 60VW;
    margin-left: 1em;
    padding: 0.3em 2.75em 0.3em 0.75em;
    background: rgba(#00A0BC, 0.06);
    color: rgba(#00A0BC, 1);
    box-shadow: 0px 1px 3px rgba(#4386cb, 0.16);
    transition: all 0.2s cubic-bezier(0.4,0.0,0.2,1);
}
.FilterSelect .filter.active:hover {
    background: rgba(#00A0BC, 1);
    color: white;
    box-shadow: 0px 3px 12px rgba(#00A0BC, 0.3);
}
.FilterSelect .filter div {
    display: inline-block;
    vertical-align: middle;

    /* Inactive Styling */
    opacity: 0;
    transition: opacity 0.1s;
}
.FilterSelect .filter.active div {
    opacity: 1;
}
.FilterSelect .filter .label {
    line-height: 1.7em;
    margin-top: -0.2rem;
    color: #202324;
}
.FilterSelect .filter.active:hover .label {
    color: white;
}
.FilterSelect .filter .icon.close {
    position: absolute;
    right: 0.75rem;
    top: 0.45rem;
}
.FilterSelect .filter .icon.close::before {
    content: 'e';
    font-size: 0.9em;
}

/* Dropdown Styling */
.FilterSelect .ui.selection.dropdown {
    background: white;
    min-width: 8em;
    user-select: none;
    transition: all 0.2s cubic-bezier(0.4,0.0,0.2,1);
}
.FilterSelect .ui.selection.dropdown:hover,
.FilterSelect .ui.selection.dropdown.active {
    background: rgba(#4386cb, 0.06);
}
.FilterSelect .ui.selection.dropdown.active {
    box-shadow: none;
}
.FilterSelect .ui.selection.active.dropdown .menu {
    border-color: #E5E5E5;
    border-radius: 6px;
    border-top-width: 1px !important;
    max-height: calc(100vh - 25em);
    max-width: 25vw;
    overflow-x: visible;
    padding: 0;
    width: auto;
}
.FilterSelect .dropdown .menu .option {
    background: white;
    border-top: none;
    cursor: pointer;
    max-width: 100%;
    overflow-x: hidden;
    padding: 0.4em 1em 0.4em 1em;
    text-overflow: ellipsis;
    width: 100%;
}
.FilterSelect .dropdown .menu .option:first-child {
    padding-top: 0.8em;
}
.FilterSelect .dropdown .menu .option:last-child {
    padding-bottom: 0.8em;
}
.FilterSelect .dropdown .menu .option.active {
    /* background: #E9F1F5; */
    color: #4386CB;
    cursor: default;
}
.FilterSelect .dropdown .menu .option.active:hover {
    background: white;
}
.FilterSelect .dropdown .menu .option:hover {
    background: #E9F1F5;
}