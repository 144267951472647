@import "Easing";

.App .View.Login,
.App .View.Signup {
    height: 100%;
    background: #f7f9fa;

    h1.ui.header {
        // background: #F7F9FA;
        color: #47525d;
        font-weight: 400;
        padding-bottom: 1rem;
    }
    h2 {
        color: #202324;
        font-weight: bold;
    }
    $gutter: 3rem;
    @function double($val) {
        @return $val + $val;
    }

    .Logo {
        position: fixed;
        left: 2rem;
        top: 1.5rem;
    }
    .TermsOfService {
        position: fixed;
        left: 2rem;
        bottom: 2.5rem;
        opacity: 0.75;

        div {
            text-align: left;
            font-size: 0.875rem;

            &.ui.horizontal.list {
                margin: 0;
                .ui.divided.horizontal.list {
                    padding: 0;
                }
            }
        }
    }
    .site-record {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
    }
    .splash {
        background: url("./../img/login.png");
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position-x: right;
        position: fixed;
        text-align: left;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
    }

    .formWrapper {
        display: table;
        position: fixed;
        top: 0;
        left: $gutter;
        height: 100%;
        min-width: 25rem;
        width: calc(50% - #{double($gutter)});

        .ui.form {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            width: 100%;

            .container {
                padding: 2rem;
                background: rgba(#f7f9fa, 0.99);

                .PasswordRequirements {
                    display: inline-block;
                    margin: -1rem 0 2rem;
                }
                .login-support-container {
                    margin-top: 40px;

                    .login-support {
                        text-decoration: underline;
                        font-size: 0.875rem;
                        color: #b7b7b7;
                    }
                }
                .field {
                    padding: 1rem 2rem;
                    // height: 5rem;

                    &.empty:not(.focused) {
                        padding-top: 3rem;
                    }

                    &:nth-of-type(2) {
                        padding-top: 0;

                        &.empty:not(.focused) {
                            padding-top: 2rem;
                        }
                    }
                }
                .ui.error.message {
                    margin: 0 2rem 2rem;
                }
                .Ripple.flat {
                    margin: 0 2rem 0.4rem 0;
                }
                .login-support-container {
                    margin-top: 2rem;

                    .login-support {
                        text-decoration: underline;
                        font-size: 0.875rem;
                        color: #b7b7b7;
                    }
                }
            }
        }

        .copyright,
        .ui.list .item {
            font-size: 0.875rem;
            color: #b7b7b7;
        }
        .copyright {
            margin-top: 40px;
        }
        .ui.list {
            width: 100%;

            a.item {
                text-decoration: underline;
            }
        }

        /** Transition Out **/
        &.exiting {
            .ui.form .container {
                h1 {
                    transition: all 0.8s;
                }
                .field,
                h2 {
                    transition: margin-top 0.8s $easeOutCubic, opacity 0.2s;
                    margin-top: -7em;
                    opacity: 0;
                }

                .ui.inverted.blue.button,
                .ui.inverted.blue.button:hover {
                    transition: all 0.4s $easeOutCubic, box-shadow 0.1s;
                    box-shadow: none !important;
                    background: none;
                    color: #47525d;
                    cursor: default;
                }

                .TermsOfService {
                    transition: opacity 0.2s $easeOutCubic;
                    opacity: 0;
                }

                .login-support-container {
                    transition: opacity 0.2s $easeOutCubic;
                    opacity: 0;
                }

                .Ripple.flat {
                    transition: all 0.4s $easeOutCubic, opacity 0.1s;
                    opacity: 0;
                    width: 0;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

.App .View.Signup {
    .formWrapper {
        .copyright {
            margin-top: 1rem;
        }
    }
}
