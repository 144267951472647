@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@keyframes scalein-y {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

@keyframes slide-up {
    from {
        transform: translateY(5rem);
    }
    to {
        transform: translateY(0);
    }
}