.InteractiveTable {
    position: relative;

    h1.ui.header {
        display: inline-block;
        font-size: 2.125em;
        line-height: 1em;
        margin-bottom: 0;
        margin-top: 0.5rem;
        font-weight: 400;

        .icon {
            font-size: 1em;
            line-height: 1em;
            vertical-align: bottom;
        }
    }
    h2 {
        font-size: 1.125rem;
        font-weight: 400;
        color: #a1a8b3;
        margin: 2rem;
    }
    .noStudents {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
    }
    .right-ui {
        float: right;
        display: flex;
        align-items: center;

        .search {
            display: inline-block;
            width: 12em;
            margin-left: 2em;
            transition: width 0.3s;

            &.focus {
                width: 18em;
            }
            .input,
            .results {
                width: 100%;
            }
            .input .icon:before {
                font-family: "moblab";
                content: "\0058";
            }
        }
        .add_new {
            display: inline-block;
            color: #4386cb;
            text-align: left;
            vertical-align: middle;

            .icon {
                display: inline-block;
                background: #4386cb;
                border-radius: 100%;
                color: white;
                font-size: 0.875rem;
                height: 2rem;
                width: 2rem;
                margin: 0;
                margin-right: 0.5rem;
                margin-left: -1rem;
                padding-top: 0.55rem;
                padding-left: 0rem;
                text-align: center;
                // vertical-align: top;
            }
        }
        &.loading .input .icon:before {
            font-family: "moblab";
            content: "";
        }
    }
    .FilterSelect {
        width: 100%;
        border-top: 1px solid #e8eaed;
        padding-top: 1em;
        margin-top: 1.5rem;

        .ui.fluid.dropdown {
            width: 20em;
        }
    }
    .pagination {
        text-align: right;

        .range,
        .Ripple {
            display: inline-block;
        }
        .Ripple {
            width: 2rem;
            height: 2rem;
            font-size: 0.8rem;

            .icon {
                position: absolute;
                top: 15%;
            }
            &.back .icon {
                left: 28%;
            }
            &.next .icon {
                left: 32%;
            }
        }
        .range {
            font-size: 0.8rem;
            display: inline-block;
            vertical-align: top;
            margin-top: 0.5rem;
            margin-right: 0.5rem;
        }
    }
}
