@import './Transitions.scss';
@import './Easing.scss';

.ui.page.modals.dimmer.transition.visible.active.inverted {
    background: white;
    color: rgba(#202324, 1);
}

.ui.modal {

    .icon.close {
        width: 2.5rem;
        height: 2.5rem;
        font-family: 'moblab';
        box-shadow: 0 1px 3px rgba(#102037, 0.16);
        border-radius: 100%;
        padding: 0.6rem;
        text-align: center;
        opacity: 1;
        color: rgba(#47525D, 1);

        &:hover{
            transform: scale(1.03);
            box-shadow: 0 2px 4px rgba(#102037, 0.16);
        }
    }

    &.basic {
        p, i, .header, .copyable {
            color: #202324;
        }
        .header {
            border: none;
        }

        .icon.close {
            position: absolute;
            background: white;
            right: 1rem;
            top: 1rem;
        }
    }

    &.fullscreen {
        width: 100%!important;
        height: 100%;
        padding: 2rem;
        margin: 0;
        text-align: center;
        overflow-y: auto;

        .icon.close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            z-index: 10001;
        }

        .content.centered {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        .actions {
            text-align: center;
            border: none;

            &.footer {
                position: absolute;
                bottom: 3rem;
                left: 0;
                width: 100%;
            }

            .Ripple.cancel {
                margin-right: 2rem;
            }
        }
    }

    &.Assign {
        position: static;
        
        $inDur: 0.8s;
        .Widget.scores_overview {
            border: none;
            box-shadow: none;
            display: inline-block;
            vertical-align: top;
            animation: counterslide $inDur $easeOutCubic;
        }
        .TierCard {
            animation: slideup $inDur $easeOutCubic;
        }
    }

    &.ChangePassword {
        position: static;

        .content {
            display: block;
            text-align: center;
            padding: 1rem 2rem;
            width: 100%;
            max-width: 29rem;
            margin: 2rem auto;

            .form {
                width: 100%;

                .ui.message {
                    .content {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }

    &.NewStudent {
        right: 10rem;
        top: 10rem;
        width: 25rem;

        .ui.form .field.empty:first-child {
            padding-top: 0;
        }
        .header {
            border: none;
            padding-bottom: 0rem;
        }
        .fields {
            animation: fadein 0.4s;
        }
        .actions {
            background: none;
            border-top: none;

            .Ripple {
                box-shadow: none;
                vertical-align: top;
                margin-top: 0.15rem;

                // vertical-align: top;
                // margin-top: 0.15rem;
            }
            .ui.button {
                padding: 0.5rem 1.5rem;
                margin-left: 1.5rem;
            }
        }

        // .form .field label {
        //     /* height: 0;
        //     transform: scale(1, 0); */
        //     color: rgba(#102037, 0.4);
        //     font-weight: 400;
        //     font-size: 1rem;
        //     margin-bottom: -2rem;
        //     margin-left: 1rem;
        //     overflow: hidden;
        //     transition: all 0.2s;
        //     user-select: none;

        //     &.show {
        //         /* height: 1rem;
        //         transform: scale(1, 1); */
        //         margin-left: 0rem;
        //         margin-bottom: 0rem;
        //     }
        // }
    }

    &.NewAdvisor {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
        .header, .actions { 
            border: none;
        }

        .content {
            text-align: center;

            h2, h3 {
                font-size: 1rem;
                color: #A1A8B3;
            }
            .campus {
                text-align: right;
                padding: 0 0 2rem 0;
                margin-top: -4.25rem;

                span {
                    margin-right: 0.5rem;
                    color: #A1A8B3;
                }
                select {
                    height: 2rem;
                    width: 12rem;
                    background: none;
                    border: 1px solid #E8EAED;
                    text-indent: 0.5rem;
                    cursor: pointer;
                }
            }
            .prompt, .dropZone {
                display: inline-block;
                width: calc(50% - 2rem);
            }
            .prompt {
                margin-right: 2rem;
                
                img {
                    width: 20rem;
                    max-width: 100%;
                }
            }
            .dropZone {
                border: dotted 2px #E8EAED;
                background: rgba(#E8EAED, 0.15);
                border-radius: 8px;
                padding: 4rem;

                .icon {
                    font-size: 2.5rem;
                    margin-bottom: 1rem;
                }
            }
            .ui.table.review {
                margin-bottom: 0.25rem;
            }
            .duplicates {
                text-align: right;
                font-style: italic;
                font-size: 0.875rem;
                color: #A1A8B3;
            }
            .InteractiveTable, h3 {
                padding-right: 1rem;
            }
            .InteractiveTable {
                th {
                    text-align: left;
                }
                tbody {
                    font-size: 1rem;
                }
                td {
                    padding: 2px;

                    &:first-of-type {
                        padding: 0rem;
                    }
                    &:nth-of-type(2){
                        border-right: 1px solid rgba(#102037, 0.16);
                    }
                    input {
                        text-align: left;
                    }
                }
            }
            h3 {
                text-align: right;
                margin: 1rem 0.5rem 0 0;
            }
        }
        .actions {
            background: none;
        }
    }

    &.StudentLink {
        position: relative;
        text-align: center;

        .content {
            display: inline-block;
            max-width: 40rem;
            text-align: left;
        }

        .copyable {
            display: block;
            margin: 1rem;
            padding: 0.2rem 4rem 0 0.2rem;
            width: auto;
            border-radius: 10rem;
            color: rgba(#202324, 1);
            cursor: pointer;

            &:hover {
                background: rgba(#4386CB, 0.16);
                color: #4386CB;
            }
            i {
                font-size: 1.5rem;
                color: inherit;
            }
            span {
                line-height: 1.5rem;
                font-size: 0.875rem;

                vertical-align: top;
                user-select: none;
                color: inherit;
            }
        }

        .ui.message {
            // display: inline-block;
            margin: 0;
            padding: 0.875rem 1rem;
            transition: all 0.2s;

            span {
                user-select: none;
            }

            &.hidden {
                display: block;
                opacity: 0;
            }
        }
    }

    &.Feedback {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .header, .actions {
            border: none;
            padding: 2rem;
        }
        .header {
            padding-bottom: 0.5rem;

            h1 {
                font-size: 1.5rem;
                color: #202324;
                margin-bottom: 0.5rem;
            }
            h2 {
                font-family: Roboto;
                font-weight: 400;
                font-size: 1.125rem;
                color: #47525D;
                margin: 0;
            }
        }
        .content .ui.form {
            width: 100%;
            padding: 0 2rem;
            display: table;

            .questions {
                display: table-cell;
                width: 60%;
                height: 100%;
                vertical-align: top;
                padding-right: 2rem;
            }
            .notes {
                display: table-cell;
                width: 40%;
                height: 100%;
                padding: 1rem 1.5rem;
                background: #FAFBFC;
                box-shadow: 0px 1px 0px rgba(#102037, 0.16);
                vertical-align: top;

                h3 {
                    font-size: 1.125rem;
                    color: #202324;
                    margin-bottom: 0.5rem;
                }
                .field {
                    height: 100%;
                    display: block;

                    textarea {
                        padding: 0;
                        color: #47525D;
                        resize: none;
                        border: none;
                        background: none;
                    }
                }
            }
        }
        .actions {
            background: none;
        }
    }

    &.APK {
        background: #F7F9FA;
        padding: 1rem;
        color: #47525D;

        .icon.close {
            top: 2rem!important;
            right: 1rem!important;
        }

        .header {
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
        .content {
            padding: 0!important;

            h2 {
                font-size: 1.25rem;
                font-weight: 400;
                text-align: left;
            }

            .ui.segment{
                border-radius: 0;
                border: none;
                box-shadow: 0px 1px 0px #E8EAED;

                .row {
                    padding-left: 2rem;
                    padding-right: 1rem;

                    p {
                        display: inline-block;
                        vertical-align: top;
                        text-align: left;

                        &:first-child {
                            width: 2rem;
                            font-weight: bold;
                        }
                        &:last-child {
                            width: calc(100% - 2rem);
                        }
                    }
                }
            }
        }
    }
}

@keyframes slideup{
    0% {
        margin-top: 2rem;
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}
@keyframes counterslide{
    0% {
        margin-top: 2rem;
    }
    100% {
        margin-top: 0;
    }
}
