@import 'Easing';

$pwd_dur: 0.2s;
$transition_dur: 0.6s;
$transition_dur_2: 1.2s;
$transition_dur_3: 1.5s;
$rotation: rotate(3deg);
$content_padding: calc(50% - 8rem);
$header_height: 19rem;
$expired_height: 80%;

.App.Portal {
    height: calc(100% - 4.75rem);
    height: 100%;
    background: white;
    animation: fadein 0.2s;

    h1.ui.dividing.header {
        background: #F7F9FA;
        color: #47525D;
        font-weight: 400;
        padding-bottom: 1rem;
    }
    h2 {
        color: #202324;
        font-weight: bold;
    }
    .splash {
        background: url("./../img/splash@2x.png");
        background-size: auto 15%;
        background-repeat: no-repeat;
        background-position-x: calc(100% + 1px);
        background-position-y: calc(100% + 1px);
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        // animation: bgScale 0.4s $easeOutCubic;

        .Logo {
            position: absolute;
            left: 1rem;
            bottom: 1rem;
            font-size: 0.8rem;
        }
    }

    .anchor {
        position: absolute;
        bottom: 0;
    }

    .scroller {
        max-height: calc(100vh - 6rem);
        overflow-x: hidden;
        overflow-y: auto;

        .container {
            position: relative;
            width: 100%;
            height: auto;
            padding-bottom: 2rem;

            .spacer {
                position: relative;
                height: 80vh;
            }
        }
    }

    .topOverlay,
    .ui.form .field,
    .ui.form h2,
    .download h2 {
        padding-left: $content_padding;
        padding-right: $content_padding;
    }

    .slantBox {
        position: absolute;
        top: 0;
        height: $header_height;
        width: 100%;
        overflow-x: hidden;
        overflow-y: visible;

        .slice {
            margin-top: -2.5rem;
            margin-left: -5rem;
            width: calc(100% + 10rem);
            height: 10rem;
            transform: $rotation;
            animation: rotatein $transition_dur;

            &.blue {
                background-image: linear-gradient(#102037, #2F5791);
            }
            &.gray {
                height: 100%;
                background: #F7F9FA;
            }
        }

        &.expired {
            height: $expired_height;
            animation: scaleHeight $transition_dur;
        }
    }

    &.inactive .slantBox .slice.gray {
        opacity: 0;
        animation: none;
    }

    .topOverlay {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: left;
        animation: fadeinDelay $transition_dur;
        transition: all 0.2s;

        h1 {
            font-size: 1.375rem;
            color: #E9F1F5;
            margin: 4rem -2rem 0 -2rem;
            text-align: center;
            transform: translateY(-50%);
        }
        p {
            font-size: 1.125rem;
            color: #47525D;
            margin-top: 10rem;
            margin-bottom: 0.5rem;

            b{
                font-weight: bold;
            }
        }
        span {
            font-size: 1.125rem;
            font-weight: bold;
            color: #00A0BC;
        }

        &.hide {
            opacity: 0;
            max-height: 0;
        }
    }

    .ui.form,
    .download,
    .activate {
        padding-top: $header_height;

        h2 {
            font-size: 1.125rem;
            color: #47525D;
            margin-bottom: 1rem;
        }
    }

    .ui.form {
        animation: fadeinDelay $transition_dur_2;

        h2 {
            text-align: left;
        }

        .error.message {
            display: block;
            margin: 1rem;
            margin-top: -3rem;
            transform: scaleY(0);
            transition: all $pwd_dur;
        }
        .action {
            display: table;
            width: 100%;
            text-align: center;
            transition: all $pwd_dur;

            .button {
                display: table-cell;
                opacity: 1;
                margin-top: 0rem;
                transition: all $pwd_dur;

                &.hide {
                    margin-top: -3rem;
                    opacity: 0;
                }
            }
        }

        &.error {
            .error.message {
                transform: scaleY(1);
                margin-top: 0;
            }
            .action {
                margin-top: 0;
            }
        }

        /** Transition Out **/
        &.exiting {
            h1 {
                transition: all 0.8s;
            }
            .field, h2{
                transition: margin-top 0.8s $easeOutCubic, opacity 0.2s $easeOutCubic;
            }

            h2, .field {
                margin-top: -7em;
                opacity: 0;
            }
            .ui.inverted.blue.button {
                transition: all 0.4s;
                box-shadow: none!important;
                background: none;
                color: #47525D;
                cursor: default;
            }
        }
    }

    .download, .activate {
        position: relative;
        text-align: center;
        animation: fadeinDelay $transition_dur_3;

        button {
            display: block;
            margin: auto;
            margin-top: 2rem;
        }
    }

    &.inactive {
        .copyright, .ui.list .item {
            font-size: 0.875rem;
            color: #B7B7B7;
        }

        .copyright {
            margin-top: 75px;
        }
        
        .ui.list {
            width: 100%;
            a.item {
                text-decoration: underline;
            }
        }
    }

}

@keyframes scaleHeight {
    0% {
        height: $header_height;
    }
    100% {
        height: $expired_height;
    }
}

@keyframes bgScale {
    0% {
        background-size: auto 50%;
    }
    // 50% {
    //     background-size: auto 50%;
    // }
    100% {
        background-size: auto 15%;
    }
}
@keyframes fadeinDelay {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes rotatein {
    0% {
        opacity: 0;
        transform: rotate(0);
    }
    50% {
        opacity: 0;
        transform: rotate(0);
    }
    100% {
        opacity: 1;
        transform: $rotation;
    }
}
