@import "./Page";
@import "./Widget";
@import "./Notes";
@import "./Easing";

$sidebar_width: 17rem;
$drawer_width: calc(#{$sidebar_width} + 0.8rem);
$page_slide_dur: 0.2s;

.View.Profile {
    .Widget .icon {
        transition: opacity $page_slide_dur $easeJeff;
    }
    .nav {
        position: relative;
        background: #47525d;
        color: white;
        height: 5.5rem;
        width: 100%;
        display: table;

        h1 {
            font-size: 2rem;
            font-weight: 400;
            display: table-cell;
            vertical-align: middle;
            color: white;
        }

        h2 {
            cursor: pointer;
            font-weight: 400;
            margin: 0;
            user-select: none;
            color: white;
            transition: opacity $page_slide_dur $easeJeff;

            &:first-child {
                font-size: 1.25rem;
                position: absolute;
                padding: 2rem;
                cursor: pointer;
            }
            &:hover {
                background: rgba(#102037, 0.2);
            }

            .icon {
                display: inline-block;
                font-size: 1rem;
                margin-right: 0.5rem;
            }
        }

        h2,
        .ui.dropdown .text {
            animation: fade_and_scale 0.25s $easeOutBack;
        }

        .ui.dropdown {
            font-size: 0.8rem;
            font-weight: bold;
            user-select: none;
            line-height: 1.5rem;
            position: absolute;
            right: 2rem;
            top: 0;
            width: 12rem;
            padding: 2rem 2rem 1rem 2rem;
            text-align: right;
            cursor: pointer;

            i {
                display: none;
            }

            .menu {
                width: 12rem;
            }
        }
    }

    .sidebar {
        width: $sidebar_width;
        height: calc(100% - 5.5rem);
        transition: left $page_slide_dur $easeJeff;
    }
    .main {
        left: $sidebar_width;
        width: calc(100% - #{$sidebar_width});
        height: calc(100% - 5.5rem);
        transition: all $page_slide_dur $easeJeff;
    }
    .NoteDrawer {
        position: absolute;
        background: white;
        left: calc(100% + #{$drawer_width});
        top: 5.5rem;
        width: $drawer_width;
        height: calc(100% - 5.5rem);
        transition: all $page_slide_dur $easeJeff;
    }
    .notesBtn {
        position: fixed;
        right: -5rem;
        bottom: 2rem;
        transition: right $page_slide_dur $easeJeff;

        .Ripple {
            background: #202324;
            width: 7.3rem;
            height: 3rem;
            font-size: 1.875rem;
            color: #a1a8b3;
            opacity: 0.8;
            cursor: pointer;
            transition: all $page_slide_dur $easeJeff;

            &:hover {
                color: white;
                opacity: 1;
                width: 7.7rem;
                height: 3.5rem;
                margin-bottom: -0.25rem;

                .icon {
                    margin-top: 0.91rem;
                }
            }
            .fill {
                display: none;
            }
            .icon {
                font-weight: 100;
                float: left;
                margin-left: -0.5rem;
                margin-top: 0.66rem;
                transition: margin-top $page_slide_dur $easeJeff;
            }
        }
    }

    &.showNotes {
        .Widget .icon.help,
        .Widget .icon.more,
        .Widget .icon.edit,
        .Widget .viewBtn,
        .student_action .icon {
            opacity: 0;
        }
        .nav h2,
        .nav .ui.dropdown,
        .Page {
            pointer-events: none;
        }
        .nav h2,
        .nav .ui.dropdown {
            opacity: 0.2;
        }

        .sidebar {
            left: -$sidebar_width;
        }
        .main {
            left: 0;
            // filter: brightness(80%);
        }
        .NoteDrawer {
            left: calc(100% - #{$drawer_width});
            box-shadow: -2px 0px 8px rgba(#102037, 0.16);
        }
        .notesBtn {
            right: -10rem;
        }
        .interceptClicks {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: $sidebar_width;
        }
    }

    .print-all-button,
    .download-report {
        background: url("./../img/download.svg");
        background-size: contain;
        background-repeat: no-repeat;
        border: 0;
        padding: 1rem;
        height: 1rem;
        width: 1rem;
        position: absolute;
        right: 6rem;
        top: 1.75rem;
        z-index: 1;
        cursor: pointer;
    }
}

@keyframes fade_and_scale {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
