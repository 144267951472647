$default_color: #84b0d0;
$suggested_color: #a29bd6;
$selected_color: #00a0bc;

.TierCard {
    display: inline-block;
    position: relative;
}
.TierCard .ui.segment {
    display: inline-block;
    margin: 0.5rem;
    padding: 1rem;
    padding-bottom: 1.5rem;
    overflow: hidden;
    width: 12rem;
    border-radius: 0.5rem;
}
.TierCard:not(.disabled) {
    cursor: pointer;
    transition: all 0.2s;
}
/* .TierCard:hover {
    background: rgba(#00A0BC, 1);
} */
.TierCard .ui.segment h1 {
    margin: -10rem -4rem 2rem -4rem;
    padding: 12rem 4.5rem 3rem;
    background: $default_color;
    box-shadow: 0 1rem 0 rgba($default_color, 0.25);
    color: white;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    min-height: 22rem;
}
.TierCard.suggested .ui.segment h1 {
    background: $suggested_color;
    box-shadow: 0 1rem 0 rgba($suggested_color, 0.25);
}
.TierCard:not(.disabled) .ui.segment:hover h1,
.TierCard.selected .ui.segment h1 {
    background: $selected_color;
    box-shadow: 0 15rem 0 $selected_color;
}
.TierCard .ui.segment h2 {
    font-size: 1rem;
    font-weight: 400;
    color: #a1a8b3;
    margin: 1rem 0 1rem 0;
}
.TierCard.selected .ui.segment h2,
.TierCard:not(.disabled) .ui.segment:hover h2 {
    color: rgba(white, 0.7);
}
.TierCard .ui.segment p {
    font-size: 1.125rem;
    font-weight: bold;
}
.TierCard.selected .ui.segment p,
.TierCard:not(.disabled) .ui.segment:hover p {
    color: white;
}
.TierCard .floating {
    position: absolute;
    width: 100%;
    top: -1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: $suggested_color;
    transition: all 0.2s;
}
.TierCard.selected .floating,
.TierCard:not(.disabled):hover .floating {
    color: $selected_color;
}
.TierCard.disabled .floating {
    top: -1rem;
    opacity: 0;
    transition: opacity 0.2s;
}
.row:hover .floating {
    opacity: 1;
}
